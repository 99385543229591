import React, { ChangeEvent, useState } from "react";
import { useField } from "formik";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import RadioGroup, { RadioGroupProps } from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioGroup: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
    button: {
      textTransform: "none",
    },
  })
);

export interface FormikRadioGroupProps extends RadioGroupProps {
  label: string;
  name: string;
  recommend?: boolean;
}

export default ({ className, ...props }: FormikRadioGroupProps) => {
  const [field, meta, helpers] = useField(props.name);
  const [showModal, setShowModal] = useState<boolean>(false);
  const classes = useStyles();
  const notString = (
    <span style={{ color: "red", fontWeight: "bold" }}>
      &nbsp;<strong>NOT</strong>&nbsp;
    </span>
  );

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    if (Number.isInteger(field.value)) {
      helpers.setValue(parseInt(event.target.value, 10));
    } else if (typeof field.value === "boolean") {
      if (typeof event.target.value === "string") {
        event.target.value = event.target.value.trim().toLowerCase();
      }
      switch (event.target.value) {
        case "true":
        case "1":
        case "on":
        case "yes":
          return helpers.setValue(true);
        default:
          return helpers.setValue(false);
      }
    } else {
      helpers.setValue(event.target.value);
    }
  }
  function showConfirmModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }
  function handleDecision(decision: string) {
    helpers.setValue(decision);
    closeModal();
  }

  return (
    <FormControl
      className={className}
      fullWidth
      margin="normal"
      error={meta.touched && !!meta.error}
    >
      <Dialog
        open={showModal}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you would{notString}recommend Fischer Homes?
        </DialogTitle>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={() => {
              handleDecision("No");
            }}
          >
            I would {notString} recommend Fischer Homes.
          </Button>
          <Button
            className={classes.button}
            onClick={() => {
              handleDecision("Yes");
            }}
            autoFocus
          >
            I would recommend Fischer Homes.
          </Button>
        </DialogActions>
      </Dialog>
      <FormLabel color="secondary" htmlFor={props.id}>
        {props.label}
      </FormLabel>
      <RadioGroup
        className={classes.radioGroup}
        color="secondary"
        data-testid={props.id}
        {...field}
        {...props}
        onChange={props.recommend ? showConfirmModal : onChange}
      >
        {props.children}
      </RadioGroup>
      <FormHelperText error={meta.touched && !!meta.error}>
        {meta.error || " "}
      </FormHelperText>
    </FormControl>
  );
};
