import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Radio, useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FormikRadioGroup, {
  FormikRadioGroupProps
} from "../../../components/FormikRadioGroup/FormikRadioGroup";
import { useField } from "formik";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lgLabel: {
      marginTop: theme.spacing(5)
    }
  })
);

interface SatisfactionQuestion extends FormikRadioGroupProps {
  primary?: boolean;
}

export default (props: SatisfactionQuestion) => {
  const [field] = useField(props.name);
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();

  return (
    <FormikRadioGroup {...props} color="secondary" row={mdUp}>
      {mdUp ? (
        <Typography className={classes.lgLabel}>Satisfied</Typography>
      ) : null}
      <FormControlLabel
        control={<Radio checked={field.value === 5} />}
        label={mdUp ? "5" : "Very Satisfied"}
        labelPlacement={mdUp ? "top" : "end"}
        value={5}
      />
      <FormControlLabel
        control={<Radio checked={field.value === 4} />}
        label={mdUp ? "4" : "Satisfied"}
        labelPlacement={mdUp ? "top" : "end"}
        value={4}
      />
      <FormControlLabel
        control={<Radio checked={field.value === 3} />}
        label={mdUp ? "3" : "Neutral"}
        labelPlacement={mdUp ? "top" : "end"}
        value={3}
      />
      <FormControlLabel
        control={<Radio checked={field.value === 2} />}
        label={mdUp ? "2" : "Dissatisfied"}
        labelPlacement={mdUp ? "top" : "end"}
        value={2}
      />
      <FormControlLabel
        control={<Radio checked={field.value === 1} />}
        label={mdUp ? "1" : "Very Dissatisfied"}
        labelPlacement={mdUp ? "top" : "end"}
        value={1}
      />
      {mdUp ? (
        <Typography className={classes.lgLabel}>Dissatisfied</Typography>
      ) : null}
    </FormikRadioGroup>
  );
};
