import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  type: Yup.string()
    .required()
    .matches(/Closing/),
  DATE: Yup.string()
    .required()
    .matches(
      /^(0?[1-9]|1[012])[/](0?[1-9]|[12][0-9]|3[01])[/]\d{4}$/,
      "Date should be in format MM/DD/YYYY"
    ),
  JOBNUMBER: Yup.string()
    .required("Job number is a required field")
    .matches(/^[A-Za-z]{2}/, "Must start with two letters (XY)")
    .length(12),
  DIVISION: Yup.string(),
  PRODUCTFAMILY: Yup.string().required().matches(/SF/),
  SURVEYTYPE: Yup.string()
    .required()
    .matches(/SFCLOSE/),
  Declined: Yup.boolean().required("This is a required field"),
  SpeakToManager: Yup.boolean().when("Declined", {
    is: true,
    then: Yup.boolean().required("This is a required field"),
  }),
  Would_recommend: Yup.string().required("This is a required field"),
  Sales_overall: Yup.number()
    .required()
    .moreThan(0, "This is a required field"),
  Sales_courteous: Yup.number()
    .required()
    .moreThan(0, "This is a required field"),
  Sales_knowledgeable: Yup.number()
    .required()
    .moreThan(0, "This is a required field"),
  Sales_timely_response: Yup.number()
    .required()
    .moreThan(0, "This is a required field"),
  Selection_overall: Yup.number()
    .required()
    .moreThan(0, "This is a required field"),
  Selection_courteous: Yup.number()
    .required()
    .moreThan(0, "This is a required field"),
  Selection_knowledgeable: Yup.number()
    .required()
    .moreThan(0, "This is a required field"),
  Selection_timely_response: Yup.number()
    .required()
    .moreThan(0, "This is a required field"),
  Const_overall: Yup.number()
    .required()
    .moreThan(0, "This is a required field"),
  Const_courteous: Yup.number()
    .required()
    .moreThan(0, "This is a required field"),
  Const_knowledgeable: Yup.number()
    .required()
    .moreThan(0, "This is a required field"),
  Const_timely_response: Yup.number()
    .required()
    .moreThan(0, "This is a required field"),
  Orientation_overall: Yup.number()
    .required()
    .moreThan(0, "This is a required field"),
  Orientation_courteous: Yup.number()
    .required()
    .moreThan(0, "This is a required field"),
  Orientation_knowledgeable: Yup.number()
    .required()
    .moreThan(0, "This is a required field"),
  Orientation_warranty: Yup.number()
    .required()
    .moreThan(0, "This is a required field"),
  Closing_overall: Yup.number()
    .required()
    .moreThan(0, "This is a required field"),
  user: Yup.object().shape({
    name: Yup.string().required(
      "Job is not available for submitting closing survey"
    ),
  }),
  address: Yup.string(),
});
