import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import ReactGA from "react-ga";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import FormikTextField from "../../components/FormikTextField/FormikTextField";
import FormikCheckbox from "../../components/FormikCheckbox/FormikCheckbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import FormikRadioGroup from "../../components/FormikRadioGroup/FormikRadioGroup";
import { useSnackbar } from "../../components/FischerFramework/FischerSnackbar";
import SatisfactionQuestion from "./Fields/SatisfactionQuestion";
import { CreateClosingSurveySchema, initialValues } from "./schema";
import { validationSchema } from "./validation";
import JobNumberComboBox from "./Fields/JobNumberCombobox";
import { useFetch } from "../../components/FischerFramework/Fetch";
import { useAuth0 } from "../../components/FischerFramework/FischerOAuth";
import { useMutation, gql } from "@apollo/client";
import FormHelperText from "@material-ui/core/FormHelperText";
import config from "../../config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorHelper: {
      marginTop: theme.spacing(1),
    },
    firstQuestion: {
      marginTop: theme.spacing(6),
    },
    questionHeader: {
      marginTop: theme.spacing(1),
    },
    submit: {
      marginTop: theme.spacing(2),
    },
  })
);

export const TEMPORARY_FORM_STATE_FOR_CLOSING_SURVEY = gql`
  mutation CreateTemporaryFormStateForClosingSurvey(
    $username: String!
    $state: JSON!
  ) {
    CreateTemporaryFormStateForClosingSurvey(
      username: $username
      state: $state
    ) {
      id
      username
      state
    }
  }
`;

export default ({ logRocketURL }: any) => {
  const history = useHistory();
  const classes = useStyles();
  const { fetch: homeownerFetch } = useFetch(
    config.fetchClients?.homeownerServices
  );
  const { captureException } = useSnackbar();
  const { user } = useAuth0();
  const [createTemporaryFormStateForClosingSurvey] = useMutation(
    TEMPORARY_FORM_STATE_FOR_CLOSING_SURVEY
  );

  const [isDeclined, setIsDeclined] = useState<boolean>(false);
  const [viewPopup, setViewPopup] = useState<boolean>(false);
  const [value, setValue] = useState("no");
  useEffect(() => {
    setIsDeclined(value === "yes" ? true : false);
  }, [value]);
  const handleClose = () => {
    setViewPopup(false);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  function GetFormattedDate() {
    var todayTime = new Date();
    return (
      todayTime.getMonth() +
      1 +
      "/" +
      todayTime.getDate() +
      "/" +
      todayTime.getFullYear()
    );
  }

  const [customerNameReadonly, setCustomerNameReadonly] = useState(false);

  async function onSubmit(this: any, form: CreateClosingSurveySchema) {
    let payload: any;

    try {
      payload = {
        type: "Closing",
        DATE: GetFormattedDate(),
        JOBNUMBER: form.JOBNUMBER,
        marketHome: form.marketHome,
        structuralSelection: form.structuralSelection,
        selectionsQuestions: form.selectionsQuestions,
        DIVISION: form.DIVISION,
        PRODUCTFAMILY: form.PRODUCTFAMILY,
        SURVEYTYPE: "SFCLOSE",
        Declined: form.Declined,
        SpeakToManager: form.Declined ? form.SpeakToManager : undefined,
        questionResponses: {
          Would_recommend: form.Declined ? undefined : form.Would_recommend,
          Sales_overall: form.Declined ? undefined : form.Sales_overall,
          Sales_courteous: form.Declined ? undefined : form.Sales_courteous,
          Sales_knowledgeable: form.Declined
            ? undefined
            : form.Sales_knowledgeable,
          Sales_timely_response: form.Declined
            ? undefined
            : form.Sales_timely_response,
          Selection_overall: form.Declined
            ? undefined
            : form.marketHome && !form.selectionsQuestions
            ? undefined
            : form.Selection_overall,
          Selection_courteous: form.Declined
            ? undefined
            : form.marketHome && !form.selectionsQuestions
            ? undefined
            : form.Selection_courteous,
          Selection_knowledgeable: form.Declined
            ? undefined
            : form.marketHome && !form.selectionsQuestions
            ? undefined
            : form.Selection_knowledgeable,
          Selection_timely_response: form.Declined
            ? undefined
            : form.marketHome && !form.selectionsQuestions
            ? undefined
            : form.Selection_timely_response,
          Const_overall: form.Declined ? undefined : form.Const_overall,
          Const_courteous: form.Declined ? undefined : form.Const_courteous,
          Const_knowledgeable: form.Declined
            ? undefined
            : form.Const_knowledgeable,
          Const_timely_response: form.Declined
            ? undefined
            : form.Const_timely_response,
          Orientation_overall: form.Declined
            ? undefined
            : form.Orientation_overall,
          Orientation_courteous: form.Declined
            ? undefined
            : form.Orientation_courteous,
          Orientation_knowledgeable: form.Declined
            ? undefined
            : form.Orientation_knowledgeable,
          Orientation_warranty: form.Declined
            ? undefined
            : form.Orientation_warranty,
          Closing_overall: form.Declined ? undefined : form.Closing_overall,
        },
        comments: form.Declined ? undefined : form.comments,
        custcommentconsent: form.Declined
          ? undefined
          : form.custcommentconsent
          ? "Yes"
          : "",
        user: form.user,
        address: form.address,
      };

      ReactGA.event({
        category: "Button",
        action: "Click",
        label: `Closing Survey Form Submission By ${
          user ? user.nickname : "unknown"
        }`,
        value: 1,
      });

      await homeownerFetch("/surveys", {
        method: "post",
        body: JSON.stringify(payload),
      });

      // Set/show success
      history.push("/thank-you");
    } catch (exception) {
      captureException(
        exception as Error,
        "There was an error submitting the survey. Please email helpdesktfg@fischerhomes.com"
      );
    }
  }

  async function onValidate(form: CreateClosingSurveySchema) {
    if (form.logRocketURL === "") form.logRocketURL = logRocketURL;
    form.Declined = isDeclined;
    await createTemporaryFormStateForClosingSurvey({
      variables: {
        username: user ? user.name : "Unknown",
        state: form,
      },
    });
  }

  return (
    <>
      <Grid item md={8} lg={6}>
        <Typography variant="h2" gutterBottom>
          Closing Survey
        </Typography>
        <Typography paragraph>
          This is the first of three surveys conducted after closing on your new
          home. Fischer Homes would appreciate your evaluation of our Sales,
          Selection, Construction, Home Orientation and Closing processes, as
          well as the personnel who responded to your needs. Your response is
          greatly appreciated.
        </Typography>
      </Grid>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={onValidate}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form>
            <Dialog
              open={viewPopup}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Disclosure"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Closing Associate, does the customer wish to decline the
                  survey?
                </DialogContentText>
                <RadioGroup
                  aria-label="Decline Survey?"
                  name="decline"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleClose();
                    onValidate(values);
                  }}
                  autoFocus
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            <Grid item xs={10} sm={8} lg={4}>
              <JobNumberComboBox
                divisionName="DIVISION"
                marketHomeName="marketHome"
                selectionsQuestionsBool="selectionsQuestions"
                userName="user.name"
                setCustomerNameReadonly={setCustomerNameReadonly}
                id="job-number-combobox"
                label="Job number"
                name="JOBNUMBER"
                onChange={setViewPopup}
              />
              <FormikTextField
                fullWidth
                id="user.name"
                label="Customer name"
                name="user.name"
                variant="outlined"
                inputProps={{ readOnly: customerNameReadonly }}
                disabled
              />
            </Grid>
            {values.Declined ? (
              <FormikRadioGroup
                id="SpeakToManager"
                label={"Would you like to have a manager contact you?"}
                name="SpeakToManager"
              >
                <FormControlLabel
                  control={<Radio />}
                  label="Yes"
                  value={true}
                />
                <FormControlLabel
                  control={<Radio />}
                  label="No"
                  value={false}
                />
              </FormikRadioGroup>
            ) : (
              <>
                <SatisfactionQuestion
                  className={classes.firstQuestion}
                  id="Sales_overall"
                  label="1. Rate your Sales Process Overall"
                  name="Sales_overall"
                />
                <Typography
                  className={classes.questionHeader}
                  component="h3"
                  variant="h6"
                >
                  My Sales Counselor:
                </Typography>
                <SatisfactionQuestion
                  id="Sales_courteous"
                  label="a. Treated me in a courteous and respectful manner"
                  name="Sales_courteous"
                />
                <SatisfactionQuestion
                  id="Sales_knowledgeable"
                  label="b. Was knowledgeable about my home, its features and the community"
                  name="Sales_knowledgeable"
                />
                <SatisfactionQuestion
                  id="Sales_timely_response"
                  label="c. Provided timely responses to my needs or questions"
                  name="Sales_timely_response"
                />
                {values.marketHome && !values.selectionsQuestions ? null : (
                  <>
                    <SatisfactionQuestion
                      className={classes.firstQuestion}
                      id="Selection_overall"
                      label="2. Rate your Selection process overall"
                      name="Selection_overall"
                    />
                    <Typography
                      className={classes.questionHeader}
                      component="h3"
                      variant="h6"
                    >
                      My Selections Coordinator:
                    </Typography>
                    <SatisfactionQuestion
                      id="Selection_courteous"
                      label="a. Treated me in a courteous and respectful manner"
                      name="Selection_courteous"
                    />
                    <SatisfactionQuestion
                      id="Selection_knowledgeable"
                      label="b. Was knowledgeable about options and upgrades"
                      name="Selection_knowledgeable"
                    />
                    <SatisfactionQuestion
                      id="Selection_timely_response"
                      label="c. Provided timely responses to my needs or questions"
                      name="Selection_timely_response"
                    />
                  </>
                )}

                <SatisfactionQuestion
                  className={classes.firstQuestion}
                  id="Const_overall"
                  label={
                    (values?.marketHome && !values.selectionsQuestions
                      ? "2"
                      : "3") + ". Rate your Construction process overall"
                  }
                  name="Const_overall"
                />
                <Typography
                  className={classes.questionHeader}
                  component="h3"
                  variant="h6"
                >
                  My Field Manager:
                </Typography>
                <SatisfactionQuestion
                  id="Const_courteous"
                  label="a. Treated me in a courteous and respectful manner"
                  name="Const_courteous"
                />
                <SatisfactionQuestion
                  id="Const_knowledgeable"
                  label="b. Was knowledgeable and informative about the construction process"
                  name="Const_knowledgeable"
                />
                <SatisfactionQuestion
                  id="Const_timely_response"
                  label="c. Provided timely responses to my needs or questions"
                  name="Const_timely_response"
                />

                <SatisfactionQuestion
                  className={classes.firstQuestion}
                  id="Orientation_overall"
                  label={
                    (values?.marketHome && !values.selectionsQuestions
                      ? "3"
                      : "4") + ". Rate your Home Orientation process overall"
                  }
                  name="Orientation_overall"
                />
                <Typography
                  className={classes.questionHeader}
                  component="h3"
                  variant="h6"
                >
                  My Fischer Homes Representative at the Home Orientation:
                </Typography>
                <SatisfactionQuestion
                  id="Orientation_courteous"
                  label="a. Treated me in a courteous and respectful manner"
                  name="Orientation_courteous"
                />
                <SatisfactionQuestion
                  id="Orientation_knowledgeable"
                  label="b. Was knowledgeable about the construction process and the features of my home"
                  name="Orientation_knowledgeable"
                />
                <SatisfactionQuestion
                  id="Orientation_warranty"
                  label="c. Reviewed the online Homeowner Use and Care Guide and the Warranty process"
                  name="Orientation_warranty"
                />

                <SatisfactionQuestion
                  className={classes.firstQuestion}
                  id="Closing_overall"
                  label={
                    (values?.marketHome && !values.selectionsQuestions
                      ? "4"
                      : "5") + ". Rate your Closing process overall"
                  }
                  name="Closing_overall"
                />

                <FormikRadioGroup
                  id="Would_recommend"
                  label={
                    (values?.marketHome && !values.selectionsQuestions
                      ? "5"
                      : "6") +
                    ". If asked by someone, would you recommend Fischer Homes?"
                  }
                  name="Would_recommend"
                  recommend={values.Would_recommend === "Yes" ? true : false}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="Yes"
                    value="Yes"
                  />
                  <FormControlLabel control={<Radio />} label="No" value="No" />
                </FormikRadioGroup>

                <Grid item md={10} lg={8}>
                  <FormikTextField
                    fullWidth
                    id="comments"
                    label="Comments"
                    multiline
                    name="comments"
                    rows={6}
                    variant="outlined"
                  />

                  <FormikCheckbox
                    id="custcommentconsent"
                    label="By checking the box, I hereby grant Fischer Homes the irrevocable permission to use my comments, whether in whole or in part, along with my name, in connection with its advertising, marketing and promotional activities, without compensation to me. Further, I hereby represent and warrant that my comments are accurate and truthful."
                    name="custcommentconsent"
                    value="Yes"
                  />
                </Grid>
              </>
            )}
            <Button
              type="submit"
              className={classes.submit}
              color="primary"
              disabled={isSubmitting}
              variant="contained"
            >
              Submit
            </Button>

            {isValid ? null : (
              <FormHelperText className={classes.errorHelper} error={true}>
                Fields are missing information. Please fill out the remaining
                questions and try again.
              </FormHelperText>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
