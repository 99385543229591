export interface CreateClosingSurveySchema {
  type: string;
  DATE: string;
  JOBNUMBER: string | "";
  Declined: boolean | undefined;
  SpeakToManager: boolean | undefined;
  marketHome: boolean;
  structuralSelection: boolean;
  selectionsQuestions: boolean;
  DIVISION: string | "";
  PRODUCTFAMILY: string;
  SURVEYTYPE: string;
  Would_recommend: "Yes" | "No" | "" | undefined;
  Sales_overall: number | undefined;
  Sales_courteous: number | undefined;
  Sales_knowledgeable: number | undefined;
  Sales_timely_response: number | undefined;
  Selection_overall: number | undefined;
  Selection_courteous: number | undefined;
  Selection_knowledgeable: number | undefined;
  Selection_timely_response: number | undefined;
  Const_overall: number | undefined;
  Const_courteous: number | undefined;
  Const_knowledgeable: number | undefined;
  Const_timely_response: number | undefined;
  Orientation_overall: number | undefined;
  Orientation_courteous: number | undefined;
  Orientation_knowledgeable: number | undefined;
  Orientation_warranty: number | undefined;
  Closing_overall: number | undefined;
  comments: string | "" | undefined;
  custcommentconsent: "Yes" | "" | undefined;
  user: {
    name: string | "" | undefined;
  };
  address: string | "" | undefined;
  logRocketURL: string | "" | undefined;
}

export const initialValues: CreateClosingSurveySchema = {
  type: "Closing",
  DATE: new Date().toLocaleDateString("en-US"),
  JOBNUMBER: "",
  Declined: false,
  SpeakToManager: false,
  marketHome: false,
  structuralSelection: false,
  selectionsQuestions: false,
  DIVISION: "",
  PRODUCTFAMILY: "SF",
  SURVEYTYPE: "SFCLOSE",
  Would_recommend: "Yes",
  Sales_overall: 5,
  Sales_courteous: 5,
  Sales_knowledgeable: 5,
  Sales_timely_response: 5,
  Selection_overall: 5,
  Selection_courteous: 5,
  Selection_knowledgeable: 5,
  Selection_timely_response: 5,
  Const_overall: 5,
  Const_courteous: 5,
  Const_knowledgeable: 5,
  Const_timely_response: 5,
  Orientation_overall: 5,
  Orientation_courteous: 5,
  Orientation_knowledgeable: 5,
  Orientation_warranty: 5,
  Closing_overall: 5,
  comments: process.env.NODE_ENV !== "production" ? "test" : "",
  custcommentconsent: "",
  user: {
    name: "",
  },
  address: "",
  logRocketURL: "",
};
